import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class CustomToastrService {
  constructor(private toastrService: ToastrService) {}

  success(message: string) {
    return this.toastrService.success(message, "Success");
  }
}
