import { prodConfig } from "./config";

export const OAuthSettings = {
  appId: "3efdd2f2-e862-4dc4-8a0e-483d903ee372",
  redirectUri: prodConfig.oauth,
  scopes: [
    "user.read",
    "mailboxsettings.read",
    "calendars.readwrite",
    "Mail.ReadWrite",
    "Mail.Read",
    "Mail.Send",
  ],
};
