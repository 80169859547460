import { createAction, props } from "@ngrx/store";
import {
  UserInfoInterface,
  UserLoginInterface,
} from "../../interfaces/user.interface";

export enum ActionType {
  LOGIN = "[AUTH] Login",
  LOGIN_SUCCESS = "[AUTH] Login success",
  LOGIN_FAILURE = "[AUTH] Login failure",
}

export const loginAction = createAction(
  ActionType.LOGIN,
  props<{ request: UserLoginInterface }>(),
);

export const loginSuccessAction = createAction(
  ActionType.LOGIN_SUCCESS,
  props<{ currentUser: UserInfoInterface }>(),
);

export const loginFailureAction = createAction(
  ActionType.LOGIN_FAILURE,
  props<{ errors: { [key: string]: string } }>(),
);
