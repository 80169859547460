import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DatesComponent } from "./compoments/dates/dates.component";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { FormsModule } from "@angular/forms";
import { provideNzI18n, uk_UA } from "ng-zorro-antd/i18n";
import { TrackBreakpointService } from "../../../services/track-breakpoint.service";

@NgModule({
  imports: [CommonModule, FormsModule, NzDatePickerModule],
  exports: [DatesComponent],
  declarations: [DatesComponent],
  providers: [provideNzI18n(uk_UA), TrackBreakpointService],
})
export class DatesModule {}
