import { ActionReducerMap } from "@ngrx/store";
import { authReducers } from "./reducers/auth.reducer";
import { LoginEffect } from "./effects/login.effect";
import { RegisterEffect } from "./effects/register.effect";
import { getAllUsersReducers } from "./reducers/get-all-users.reducer";
import { GetAllUsersEffect } from "./effects/get-all-users.effect";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AppReducers: ActionReducerMap<any> = {
  auth: authReducers,
  getAllUsers: getAllUsersReducers,
};

export const AppEffects = [LoginEffect, RegisterEffect, GetAllUsersEffect];
