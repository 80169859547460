import { createAction, props } from "@ngrx/store";
import { AllUsersInterface } from "../../interfaces/user.interface";

export enum ActionType {
  GET_ALL_USERS = "[USERS] Get all users",
  GET_ALL_USERS_SUCCESS = "[USERS] Get all users success",
  GET_ALL_USERS_FAILURE = "[USERS] Get all users failure",
}

export const getAllUsersAction = createAction(ActionType.GET_ALL_USERS);

export const getAllUsersSuccessAction = createAction(
  ActionType.GET_ALL_USERS_SUCCESS,
  props<{ allUsers: AllUsersInterface[] }>(),
);

export const getAllUsersFailureAction = createAction(
  ActionType.GET_ALL_USERS_FAILURE,
);
