import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AuthService } from "../../services/auth.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import {
  loginAction,
  loginFailureAction,
  loginSuccessAction,
} from "../actions/login.action";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { of } from "rxjs";
import { DynamicLoaderService } from "../../services/dynamic-loader.serice";
import { SnapshotService } from "../../services/snapshot.service";
import { NavigationService } from "../../services/navigation.service";

@Injectable()
export class LoginEffect {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private dynamicLoader: DynamicLoaderService,
    private snapshotService: SnapshotService,
    private navigationService: NavigationService,
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginAction),
      switchMap(({ request }) => {
        return this.authService.loginUser(request).pipe(
          map((currentUser) => {
            this.snapshotService.checkAdminStatus(currentUser.user_info.roles);
            this.snapshotService.checkSeoStatus(currentUser.user_info.roles);
            if (currentUser.user_info.roles.length !== 0) {
              this.authService.setAuthFromLocalStorage(currentUser.accessToken);
            }
            return loginSuccessAction({ currentUser: currentUser.user_info });
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            return of(
              loginFailureAction({ errors: errorResponse.error.errors }),
            );
          }),
        );
      }),
    ),
  );

  redirectAfterSubmit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginSuccessAction),
        tap(() => {
          this.dynamicLoader.loadAuthModule().then((authModule) => {
            this.router.resetConfig([
              {
                path: "",
                loadChildren: () => Promise.resolve(authModule),
              },
            ]);
            this.router.navigate([
              this.navigationService.getAndClearRedirectUrl(),
            ]);
          });
        }),
      ),
    { dispatch: false },
  );
}
