import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  isLocalhost: boolean = ["localhost", "127.0.0.1", "::1"].includes(
    window.location.hostname,
  );

  constructor(private router: Router) {}

  redirectToProduction() {
    const currentRoute = this.router.url;
    const productionUrl = `https://zlinkcrm.com${currentRoute}`;
    window.open(productionUrl, "_blank");
  }
}
