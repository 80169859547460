import { Injectable } from "@angular/core";
import { CanActivate, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { OutlookService } from "../../../../services/outlook.service";

@Injectable({
  providedIn: "root",
})
export class MySiteGuard implements CanActivate {
  constructor(private outlookService: OutlookService) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.outlookService.checkLastMails();
  }
}
