import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  IMySitesBadgesInterface,
  IPendingPaymentDomainInterface,
  LastMessageInterface,
  ManualByDomainInterface,
  MySiteFullInterface,
} from "../types/my-site.interface";
import { DonorReferencesResponseInterface } from "../../../interfaces/links.interface";
import {
  MakePaymentResponseInterface,
  PaymentInterface,
} from "../../pending-payment/types/payment.interface";

@Injectable()
export class MySiteService {
  tab = "new";
  constructor(private http: HttpClient) {}

  getSitesTab(tab?: string): Observable<{
    result: MySiteFullInterface[];
    badges: IMySitesBadgesInterface;
  }> {
    if (tab) {
      this.tab = tab;
    }
    return this.http.get<{
      result: MySiteFullInterface[];
      badges: IMySitesBadgesInterface;
    }>(`my-sites/${this.tab}`);
  }

  changeFavourite(
    domain: string,
    value: boolean,
  ): Observable<{ [key: string]: string }> {
    return this.http.post<{ [key: string]: string }>("my-sites/favorite", {
      domain,
      value,
    });
  }

  addSite(data: { domain: string }): Observable<{ [key: string]: string }> {
    return this.http.post<{ [key: string]: string }>("my-sites", data);
  }

  checkLastMessage(domain: string): Observable<LastMessageInterface> {
    return this.http.post<LastMessageInterface>(`my-sites/last-message`, {
      domain,
    });
  }

  makeReport(
    reportInfo: { reportType: string; report: string },
    id: string,
  ): Observable<{ [key: string]: string }> {
    return this.http.post<{ [key: string]: string }>(`my-sites/report/${id}`, {
      reportType: reportInfo.reportType,
      report: reportInfo.reportType === "other" ? reportInfo.report : undefined,
    });
  }

  removeSite(domain: string): Observable<{ [key: string]: string }> {
    return this.http.post<{ [key: string]: string }>("my-sites/remove", {
      domain,
    });
  }

  removeReport(id: string): Observable<{ [key: string]: string }> {
    return this.http.delete<{ [key: string]: string }>(
      `my-sites/reports/${id}`,
    );
  }

  //remove
  addContact(email: string, id: string): Observable<{ [key: string]: string }> {
    return this.http.post<{ [key: string]: string }>(
      `my-sites/add-email/${id}`,
      { email },
    );
  }

  findLinks(domain: string): Observable<IPendingPaymentDomainInterface[]> {
    return this.http.post<IPendingPaymentDomainInterface[]>(
      "my-sites/payments",
      {
        domain,
      },
    );
  }

  getDonorReferences(
    id: string,
  ): Observable<DonorReferencesResponseInterface[]> {
    return this.http.get<DonorReferencesResponseInterface[]>(
      `payments/referred/${id}`,
    );
  }

  getContacts(
    domain: string,
  ): Observable<{ contacts: string[]; domains: string[] }> {
    return this.http.post<{ contacts: string[]; domains: string[] }>(
      `my-sites/batch`,
      { domain },
    );
  }

  createPayment(
    data: PaymentInterface,
  ): Observable<MakePaymentResponseInterface> {
    return this.http.post<MakePaymentResponseInterface>("payments", data);
  }

  editPayment(
    data: PaymentInterface,
    id: string,
  ): Observable<MakePaymentResponseInterface> {
    return this.http.post<MakePaymentResponseInterface>(`payments/${id}`, data);
  }

  deletePayment(id: string): Observable<{ [key: string]: string }> {
    return this.http.delete<{ [key: string]: string }>(`payments/${id}`);
  }

  updateLastResponses(
    cases: { email: string; date: number }[],
  ): Observable<{ [key: string]: string }> {
    return this.http.post<{ [key: string]: string }>("messages/update-last", {
      cases,
    });
  }

  markAnswered(emails: string[]): Observable<{ [key: string]: string }> {
    return this.http.post<{ [key: string]: string }>("messages/mark-answered", {
      emails,
    });
  }

  getMessagesBatch(): Observable<{ domains: string[] }> {
    return this.http.get<{ domains: string[] }>("messages/batch");
  }

  manualByDomain(domain: string): Observable<ManualByDomainInterface[]> {
    return this.http.post<ManualByDomainInterface[]>(`manual/by-domain`, {
      domain,
    });
  }
}
