import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { DynamicLoaderService } from "../../services/dynamic-loader.serice";
import {
  registerAction,
  registerFailureAction,
  registerSuccessAction,
} from "../actions/register.action";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";
import { NavigationService } from "../../services/navigation.service";
import { SnapshotService } from "../../services/snapshot.service";
import { PAGE_ROUTES } from "../../enums/page-routes.enum";

@Injectable()
export class RegisterEffect {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private dynamicLoader: DynamicLoaderService,
    private navigationService: NavigationService,
    private snapshotService: SnapshotService,
  ) {}

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(registerAction),
      switchMap(({ request }) => {
        return this.authService.registerUser(request).pipe(
          map((currentUser) => {
            this.snapshotService.checkAdminStatus(currentUser.user_info.roles);
            this.snapshotService.checkSeoStatus(currentUser.user_info.roles);
            if (currentUser.user_info.roles.length !== 0) {
              this.authService.setAuthFromLocalStorage(currentUser.accessToken);
            }
            return registerSuccessAction({
              currentUser: currentUser.user_info,
            });
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            return of(registerFailureAction({ errors: errorResponse.error }));
          }),
        );
      }),
    ),
  );

  redirectAfterSubmit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(registerSuccessAction),
        tap((res) => {
          if (res.currentUser.roles.length === 0) {
            this.router.navigate([`/${PAGE_ROUTES.CONSIDERATION}`]);
          } else {
            this.dynamicLoader.loadAuthModule().then((authModule) => {
              this.router.resetConfig([
                {
                  path: "",
                  loadChildren: () => Promise.resolve(authModule),
                },
              ]);
              this.router.navigate([
                this.navigationService.getAndClearRedirectUrl(),
              ]);
            });
          }
        }),
      ),
    { dispatch: false },
  );
}
