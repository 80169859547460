import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../environments/environment";
import { prodConfig } from "../../config";
import { catchError } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { LOCALSTORAGE } from "../enums/localstorage.enum";

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  private BASE_URL = prodConfig.api;
  private BASE_URL_OLD = prodConfig.oldApi;
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  constructor(private toastrService: ToastrService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const nextRequest = request.clone({
      headers: this.prepareHeaders(request),
      url: this.assignRequestURL(request),
      withCredentials: true,
    });
    return next.handle(nextRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (request.url !== "user") {
          this.toastrService.error(error.error.error, "Error");
        }
        return throwError(error);
      }),
    );
  }

  private assignRequestURL(request: HttpRequest<unknown>): string {
    if (request.url.includes("assets/")) {
      return `${request.url}`;
    }
    const CUSTOM = request.headers.get("customlink");
    if (CUSTOM) {
      if (CUSTOM === "external") {
        return `${request.url}`;
      }
      if (CUSTOM === "outlook") {
        return `https://graph.microsoft.com/v1.0${request.url}`;
      }
      if (CUSTOM === "old") {
        return `${this.BASE_URL_OLD}${request.url}`;
      }
    }
    return `${this.BASE_URL}${request.url}`;
  }

  private prepareHeaders(request: HttpRequest<unknown>) {
    let headers: HttpHeaders = request.headers;

    if (!headers.get("no-auth")) {
      const auth = JSON.parse(localStorage.getItem(this.authLocalStorageToken));
      headers = headers.append("Authorization", "Bearer " + auth);
    } else {
      headers = headers.delete("no-auth");
    }

    if (headers.get("email-auth")) {
      const auth = JSON.parse(localStorage.getItem(LOCALSTORAGE.OUTLOOK_TOKEN));
      headers = headers.append("Authorization", "Bearer " + auth);
    }

    if (headers.has("no-type")) {
      headers = headers.delete("no-type");
      headers = headers.delete("content-type");
    } else if (!headers.get("content-type")) {
      headers = headers.append("content-type", "application/json");
    }
    if (headers.has("customlink")) {
      headers = headers.delete("customlink");
    }

    return headers;
  }
}
