import { Injectable, signal } from "@angular/core";
import { PAGE_ROUTES } from "../enums/page-routes.enum";
import { Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { LOCALSTORAGE } from "../enums/localstorage.enum";

@Injectable({
  providedIn: "root",
})
export class TitleService {
  topBarTitle = signal<string>(
    localStorage.getItem(LOCALSTORAGE.TITLE_TOP_BAR),
  );
  private titleMap = Object.keys(PAGE_ROUTES).reduce((map, routeKey) => {
    map[PAGE_ROUTES[routeKey]] = this.formatTitle(PAGE_ROUTES[routeKey]);
    return map;
  }, {});

  constructor(
    private titleService: Title,
    private router: Router,
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const url = this.router.routerState.snapshot.url;
        const parts = url.split("/");
        let title = "MrrLinks";
        let titleTopBar = "Dashboard";
        if (parts.length > 2) {
          const titleKey = parts.slice(2).join("/");
          title = this.titleMap[titleKey]
            ? `${this.titleMap[parts[1]]} - ${this.titleMap[titleKey]}`
            : title;
          titleTopBar = this.titleMap[parts[1]];
        } else {
          title = this.titleMap[parts[1]] || title;
          titleTopBar = this.titleMap[parts[1]] || titleTopBar;
        }
        this.titleService.setTitle(title);
        this.topBarTitle.set(titleTopBar);
        localStorage.setItem(LOCALSTORAGE.TITLE_TOP_BAR, titleTopBar);
      });
  }

  private formatTitle(route: string): string {
    return route
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
}
