import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import {
  getAllUsersAction,
  getAllUsersFailureAction,
  getAllUsersSuccessAction,
} from "../actions/get-all-users.action";
import { AllUsersInterface } from "../../interfaces/user.interface";

const initialState: {
  isLoading: boolean;
  allUsers: AllUsersInterface[] | null;
} = {
  isLoading: false,
  allUsers: null,
};

const getAllUsersReducer = createReducer(
  initialState,
  on(getAllUsersAction, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(getAllUsersSuccessAction, (state, action) => ({
    ...state,
    isLoading: false,
    allUsers: action.allUsers,
  })),
  on(getAllUsersFailureAction, (state) => ({
    ...state,
    isLoading: false,
  })),
);

export const getAllUsersFeatureSelector = createFeatureSelector<{
  isLoading: boolean;
  allUsers: AllUsersInterface[];
}>("getAllUsers");

export const allUsersSelector = createSelector(
  getAllUsersFeatureSelector,
  (state: { isLoading: boolean; allUsers: AllUsersInterface[] }) =>
    state.allUsers,
);

export function getAllUsersReducers(
  state: { isLoading: boolean; allUsers: AllUsersInterface[] },
  action: Action,
) {
  return getAllUsersReducer(state, action);
}
