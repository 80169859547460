import { NgModule } from "@angular/core";
import { MySiteGuard } from "./services/my-site.guard";
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  PublicClientApplication,
} from "@azure/msal-browser";
import { OAuthSettings } from "../../../../oauth";
import { MSAL_INSTANCE, MsalModule, MsalService } from "@azure/msal-angular";

let msalInstance: IPublicClientApplication = null;

export function MSALInstanceFactory(): IPublicClientApplication {
  msalInstance =
    msalInstance ||
    new PublicClientApplication({
      auth: {
        clientId: OAuthSettings.appId,
        redirectUri: OAuthSettings.redirectUri,
        postLogoutRedirectUri: OAuthSettings.redirectUri,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
      },
      system: {
        allowNativeBroker: false,
      },
    });
  msalInstance.initialize();
  return msalInstance;
}

@NgModule({
  declarations: [],
  imports: [MsalModule],
  providers: [
    MsalService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    MySiteGuard,
  ],
  exports: [MsalModule],
})
export class MailAbstractModule {}
