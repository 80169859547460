import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { RolesEnum } from "../enums/roles.enum";

@Injectable()
export class SnapshotService {
  isAuth = false;
  private isAdminSubject = new BehaviorSubject<boolean>(false);
  isAdmin$: Observable<boolean> = this.isAdminSubject.asObservable();
  private isSeoSubject = new BehaviorSubject<boolean>(false);
  isSeo$: Observable<boolean> = this.isSeoSubject.asObservable();

  get isAdmin(): boolean {
    return this.isAdminSubject.value;
  }

  get isSeo(): boolean {
    return this.isSeoSubject.value;
  }

  setIsAuth(value: boolean) {
    this.isAuth = value;
  }

  checkAdminStatus(roles: string[]): void {
    const isAdmin = roles.some((role) =>
      [RolesEnum.ADMIN, RolesEnum.SUPER_ADMIN].includes(role as RolesEnum),
    );
    this.setIsAdmin(isAdmin);
  }

  checkSeoStatus(roles: string[]): void {
    const isSeo = roles.some((role) =>
      [RolesEnum.SEO].includes(role as RolesEnum),
    );
    this.setIsSeo(isSeo);
  }

  setIsAdmin(value: boolean): void {
    this.isAdminSubject.next(value);
  }

  setIsSeo(value: boolean): void {
    this.isSeoSubject.next(value);
  }
}
