import { createAction } from "@ngrx/store";

export enum UserActionsTypes {
  USER_LOGOUT_SUCCESS = "[Logout] User logout success",
  USER_LOGOUT_FAILURE = "[Logout] User logout failure",
}

export const userLogoutSuccessAction = createAction(
  UserActionsTypes.USER_LOGOUT_SUCCESS,
);

export const userLogoutFailureAction = createAction(
  UserActionsTypes.USER_LOGOUT_FAILURE,
);
