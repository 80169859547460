/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum PAGE_ROUTES {
  DASHBOARD = "dashboard",
  LINKS = "links",
  WORK = "work",
  DOMAINS = "domains",
  BUYING_HELPER = "buying-helper",
  BUYING_HELPER_DONORS = "donors",
  BUYING_HELPER_PROJECTS = "projects",
  MY_SITES = "my-sites",
  PENDING_PAYMENTS = "payments",
  ABNORMAL_PRICES = "abnormal-prices",
  EXPENSES = "expenses",
  MANAGE_USERS = "manage-users",
  TRANSFER = "transfer",
  DUPLICATES = "duplicates",
  LINKS_CHANGES = "links-changes",
  ANCHORS_CHANGES = "anchors-changes",
  TASKS = "tasks",
  PROJECTS = "projects",
  SEO_REFERENCES = "seo-references",
  LOGIN = "login",
  REGISTER = "register",
  CONSIDERATION = "consideration",
  USER_PROFILE = "profile",
  PERSONAL_INFORMATION = "personal-information",
  ACCOUNT_INFORMATION = "account-information",
  CHANGE_PASSWORD = "change-password",
  WELCOME_MESSAGES = "welcome-messages",
  NEW_LINK = "new",
  BAD_DOMAIN = "bad-domain",
  CANONICAL = "canonical",
  CLOUDFLARE = "cloudflare",
  LOST = "lost",
  MANUAL = "manual",
  RENDER = "render",

  MS_ARCHIVED = "archived",
  MS_CONVERSATION = "conversation",
  MS_FAVORITES = "favorites",
  MS_NEW = "new",
  MS_NOT_RESPONDED = "notResponded",
  MS_PAID = "paid",
  MS_PROCESSED = "processed",
  MS_PUBLISHED = "published",
  MS_REPORTED = "reported",
  MS_REPORTING = "reporting",

  PAYMENTS_ALL = "all",
  PAYMENTS_PENDING = "pending",
  PAYMENTS_PROCESSED = "processed",
  PAYMENTS_PAID = "paid",
  PAYMENTS_REJECTED = "rejected",
  PAYMENTS_ARCHIVED = "archived",

  DUPLICATES_UNRESOLVED = "unresolved",
  DUPLICATES_RESOLVED = "resolved",

  ANCHORS_CHANGES_NEW = "new",
  ANCHORS_CHANGES_RESOLVED = "resolved",
  ANCHORS_CHANGES_APPROVED = "approved",

  LINKS_CHANGES_ALL = "all",
  LINKS_CHANGES_NEW = "new",
  LINKS_CHANGES_APPROVED = "approved",
  LINKS_CHANGES_PENDING = "pending",
  LINKS_CHANGES_REJECTED = "rejected",

  WM_ALL = "all",
  WM_WELCOME = "welcome",
  WM_LOST = "lost",
}
