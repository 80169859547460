export enum LOCALSTORAGE {
  THEME_KEY = "theme",
  THEME_KEY_DARK = "dark",
  THEME_KEY_LIGHT = "light",
  MENU_COLLAPSED_KEY = "menuCollapsed",
  MENU_ATTRIBUTE_KEY = "data-theme",
  TITLE_TOP_BAR = "title-top-bar",
  REDIRECT_URL = "redirectUrl",
  HIDDEN_COLUMNS = "hiddenColumns",
  HIDDEN_ACTIONS = "hiddenActions",
  LINKS_FILTERS = "linksFilters",
  COLUMNS_LINKS = "columnsLinks",
  DOMAINS_FOUND = "domainsFound",
  PAYMENT_FILTERS = "paymentFilters",
  FIXED_ACTIONS = "fixedActions",
  OUTLOOK_TOKEN = "messagesToken",
}
