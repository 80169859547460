import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import {
  getCurrentUserSuccessAction,
  updateCurrentUserSuccessAction,
} from "../actions/get-current-user.action";
import {
  loginAction,
  loginFailureAction,
  loginSuccessAction,
} from "../actions/login.action";
import {
  userLogoutFailureAction,
  userLogoutSuccessAction,
} from "../actions/user-logout.action";
import {
  registerAction,
  registerFailureAction,
  registerSuccessAction,
} from "../actions/register.action";
import { UserStateInterface } from "../types/user-state.interface";

const UserInitialState: UserStateInterface = {
  currentUser: null,
  isLogin: false,
  errors: null,
  isSubmitting: false,
};

const authReducer = createReducer(
  UserInitialState,
  on(userLogoutSuccessAction, (state) => ({
    ...state,
    currentUser: null,
    isLogin: false,
  })),

  on(userLogoutFailureAction, (state) => ({
    ...state,
    currentUser: null,
    isLogin: true,
  })),

  on(loginAction, (state) => ({
    ...state,
    isLoading: true,
    isSubmitting: true,
  })),
  on(loginSuccessAction, (state, action) => ({
    ...state,
    currentUser: action.currentUser,
    isLogin: true,
    isSubmitting: false,
  })),
  on(loginFailureAction, (state, action) => ({
    ...state,
    isLogin: false,
    errors: action.errors,
    isSubmitting: false,
  })),

  on(registerAction, (state) => ({
    ...state,
    isSubmitting: true,
  })),
  on(registerSuccessAction, (state, action) => ({
    ...state,
    currentUser: action.currentUser,
    isLogin: true,
    isSubmitting: false,
  })),
  on(registerFailureAction, (state, action) => ({
    ...state,
    isLogin: false,
    errors: action.errors,
    isSubmitting: false,
  })),
  on(getCurrentUserSuccessAction, (state, action) => ({
    ...state,
    currentUser: action.currentUser,
    isLogin: true,
  })),
  on(updateCurrentUserSuccessAction, (state, action) => ({
    ...state,
    isLoading: false,
    currentUser: action.currentUser,
  })),
);

export const userFeatureSelector =
  createFeatureSelector<UserStateInterface>("auth");

export const currentUserSelector = createSelector(
  userFeatureSelector,
  (state: UserStateInterface) => state.currentUser,
);

export const isSubmittingSelector = createSelector(
  userFeatureSelector,
  (state: UserStateInterface) => state.isSubmitting,
);

export function authReducers(state: UserStateInterface, action: Action) {
  return authReducer(state, action);
}
