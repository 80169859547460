/* eslint-disable no-useless-escape,@typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import { Client } from "@microsoft/microsoft-graph-client";
import { HttpClient } from "@angular/common/http";
import { MsalService } from "@azure/msal-angular";
import { Router } from "@angular/router";
import { MySiteService } from "../pages/my-sites/services/my-site.service";
import { OAuthSettings } from "../../oauth";
import { combineLatest, mergeMap, Observable, of } from "rxjs";
import { delay, map, tap } from "rxjs/operators";
import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { LOCALSTORAGE } from "../enums/localstorage.enum";

@Injectable({
  providedIn: "root",
})
export class OutlookService {
  public authenticated: boolean;
  public user?;
  public graphClient: Client;

  constructor(
    private msalService: MsalService,
    private http: HttpClient,
    private mySiteService: MySiteService,
    private router: Router,
  ) {}

  // Prompt the user to sign in and
  // grant consent to the requested permission scopes
  async signIn(): Promise<void | boolean> {
    try {
      let result = null;
      const accs: AccountInfo[] = this.msalService.instance.getAllAccounts();
      if (accs.length && !!localStorage.getItem(LOCALSTORAGE.OUTLOOK_TOKEN)) {
        result = await this.acquireTokenSilent(accs[0]).toPromise();
      } else {
        result = await this.msalService.loginPopup(OAuthSettings).toPromise();
      }

      if (result) {
        localStorage.setItem(LOCALSTORAGE.OUTLOOK_TOKEN, result.accessToken);
        this.msalService.instance.setActiveAccount(result.account);
        this.authenticated = true;
      }
      return !!result;
    } catch (e) {
      return false;
    }
  }

  // Silently request an access token
  async getAccessToken(): Promise<string> {
    let result = null;
    if (localStorage.getItem(LOCALSTORAGE.OUTLOOK_TOKEN)) {
      result = {
        accessToken: localStorage.getItem(LOCALSTORAGE.OUTLOOK_TOKEN),
      };
    } else {
      result = await this.msalService
        .acquireTokenSilent({
          scopes: OAuthSettings.scopes,
        })
        .toPromise();
    }

    if (result) {
      return result.accessToken;
    }

    // Couldn't get a token
    this.authenticated = false;
    return "";
  }

  // Get token for work with your email
  async getMessages(): Promise<void> {
    if (!this.graphClient) {
      this.graphClient = Client.init({
        authProvider: async (done) => {
          // Get the token from the auth service
          const token = await this.getAccessToken().catch((reason) => {
            done(reason, null);
          });

          if (token) {
            localStorage.setItem(LOCALSTORAGE.OUTLOOK_TOKEN, token);
            done(null, token);
          } else {
            done("Could not get an access token", null);
          }
        },
      });

      try {
        await this.graphClient;
      } catch (error) {
        throw Error(JSON.stringify(error, null, 2));
      }
    }
  }

  // Get user info
  userInfo(): Promise<any> {
    return this.getMessages().then(() => {
      return this.graphClient.api(`/me`).get();
    });
  }

  // Show inbox messages
  inbox(skipNum): Promise<any> {
    return this.graphClient
      .api(`/me/mailFolders(\'Inbox\')/messages?skip=${skipNum}&$top=100`)
      .get();
  }

  // Show sent messages
  sent(skipNum): Promise<any> {
    return this.graphClient
      .api(`/me/mailFolders(\'sentitems\')/messages?skip=${skipNum}&$top=15`)
      .get();
  }

  // Send a new message
  send(message): Promise<any> {
    return this.graphClient
      .api(`/me/sendMail`)
      .post(message)
      .catch(async () => {
        await this.signIn();
        return this.send(message);
      });
  }

  // Add statistics sent mail
  addStatsSentMail(sentEmails: any[]): Observable<any> {
    return this.http.post<any>(`messages/add`, { emails: sentEmails });
  }

  // Get message from id
  getMessage(id): Promise<any> {
    return this.graphClient.api(`/me/messages/${id}`).get();
  }

  checkLastMails(): Observable<any> {
    return of(this.authenticated).pipe(
      mergeMap((res) => {
        if (!res) {
          return this.signIn();
        } else {
          return of(true);
        }
      }),
      map((res) => {
        if (res) {
          of(true)
            .pipe(
              delay(1000),
              tap(() => {
                this.getMessages().then(() => {
                  this.inbox(0).then((resp) => {
                    const emails = [];
                    const responses = [];

                    for (const letter of resp.value) {
                      const email = letter.from.emailAddress.address;
                      if (!emails.includes(email)) {
                        emails.push(email);
                      }

                      const emailCase = {
                        email: letter.from.emailAddress.address,
                        date: new Date(letter.sentDateTime).getTime(),
                      };

                      responses.push(emailCase);
                    }

                    combineLatest([
                      this.mySiteService.updateLastResponses(responses),
                      this.mySiteService.markAnswered(emails),
                    ]).subscribe();
                  });
                });
              }),
            )
            .subscribe();
        }
        return res || this.router.parseUrl("/");
      }),
    );
  }

  acquireTokenSilent(acc: AccountInfo): Observable<AuthenticationResult> {
    return this.msalService.acquireTokenSilent({
      ...OAuthSettings,
      account: acc,
    });
  }
}
