import { createAction, props } from "@ngrx/store";
import {
  UserInfoInterface,
  UserRegisterInterface,
} from "../../interfaces/user.interface";

export enum ActionType {
  REGISTER = "[AUTH] Register",
  REGISTER_SUCCESS = "[AUTH] Register success",
  REGISTER_FAILURE = "[AUTH] Register failure",
}

export const registerAction = createAction(
  ActionType.REGISTER,
  props<{ request: UserRegisterInterface }>(),
);

export const registerSuccessAction = createAction(
  ActionType.REGISTER_SUCCESS,
  props<{ currentUser: UserInfoInterface }>(),
);

export const registerFailureAction = createAction(
  ActionType.REGISTER_FAILURE,
  props<{ errors: { [key: string]: string } }>(),
);
