import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, ROUTES } from "@angular/router";
import { RouteResolver } from "./services/router-resolver";

@NgModule({
  imports: [
    RouterModule.forRoot([], {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
  providers: [
    RouteResolver,
    {
      provide: ROUTES,
      useFactory: (resolver: RouteResolver) => resolver.resolveRoutes(),
      deps: [RouteResolver],
      multi: true,
    },
  ],
})
export class AppRoutingModule {}
