import { Injectable } from "@angular/core";
import { SnapshotService } from "./snapshot.service";
import { Routes } from "@angular/router";

@Injectable()
export class RouteResolver {
  constructor(private snapshotService: SnapshotService) {}

  resolveRoutes(): Routes {
    return [
      {
        path: "",
        loadChildren: () =>
          this.snapshotService.isAuth
            ? import("../pages/auth/auth.module").then((m) => m.AuthModule)
            : import("../pages/not-auth/not-auth.module").then(
                (m) => m.NotAuthModule,
              ),
      },
    ];
  }
}
