import { createAction, props } from "@ngrx/store";
import { UserInfoInterface } from "../../interfaces/user.interface";

export enum ActionType {
  GET_CURRENT_USER = "[AUTH] Get current user",
  GET_CURRENT_USER_SUCCESS = "[AUTH] Get current user success",
  GET_CURRENT_USER_FAILURE = "[AUTH] Get current user failure",

  UPDATE_CURRENT_USER_SUCCESS = "[Auth] Update current user success",
}

export const getCurrentUserAction = createAction(ActionType.GET_CURRENT_USER);

export const getCurrentUserSuccessAction = createAction(
  ActionType.GET_CURRENT_USER_SUCCESS,
  props<{ currentUser: UserInfoInterface }>(),
);

export const getCurrentUserFailureAction = createAction(
  ActionType.GET_CURRENT_USER_FAILURE,
);

export const updateCurrentUserSuccessAction = createAction(
  ActionType.UPDATE_CURRENT_USER_SUCCESS,
  props<{ currentUser: UserInfoInterface }>(),
);
