import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SelectComponent } from "./components/select/select.component";
import { NzSelectModule } from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import { CloseSelectOnScrollDirective } from "../../../directives/close-select/close-select.directive";

@NgModule({
  imports: [CommonModule, NzSelectModule, FormsModule],
  exports: [SelectComponent],
  declarations: [SelectComponent, CloseSelectOnScrollDirective],
})
export class SelectModule {}
