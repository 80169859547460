import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SnapshotService } from "./services/snapshot.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AppInterceptor } from "./services/app.interceptor";
import { AuthService } from "./services/auth.service";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment.prod";
import { EffectsModule } from "@ngrx/effects";
import { AppEffects, AppReducers } from "./store";
import { Store, StoreModule } from "@ngrx/store";
import { NavigationService } from "./services/navigation.service";
import { getCurrentUserSuccessAction } from "./store/actions/get-current-user.action";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { EnterReasonComponent } from "./modules/modal/enter-reason/enter-reason.component";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { SelectModule } from "./modules/partials/select/select.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ValidationErrorsModule } from "./modules/partials/validation-errors/validation-errors.module";
import { ShowReasonComponent } from "./modules/modal/show-reason/show-reason.component";
import { LinksChatComponent } from "./modules/modal/links-chat/links-chat.component";
import { ManualTrackingComponent } from "./modules/modal/manual-tracking/manual-tracking.component";
import { HistoryLinkModalComponent } from "./modules/modal/history-link-modal/history-link-modal.component";
import { DatesModule } from "./modules/partials/dates/dates.module";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { BlackListRemoveComponent } from "./modules/modal/black-list-remove/black-list-remove.component";
import { MailAbstractModule } from "./modules/partials/mail-abstract/mail-abstract.module";
import { OutlookService } from "./services/outlook.service";
import { TitleService } from "./services/title-resolver";
import { CustomToastrService } from "./services/custom-toastr.service";
import { AngularSvgIconModule } from "angular-svg-icon";

function appInitializer(authService: AuthService, store: Store) {
  return () => {
    return new Promise<void>((resolve) => {
      authService
        .getCurrentUser()
        .subscribe((currentUser) => {
          store.dispatch(
            getCurrentUserSuccessAction({ currentUser: currentUser }),
          );
        })
        .add(() => resolve());
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    EnterReasonComponent,
    ShowReasonComponent,
    LinksChatComponent,
    ManualTrackingComponent,
    HistoryLinkModalComponent,
    BlackListRemoveComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot(AppReducers),
    EffectsModule.forRoot(AppEffects),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    ToastrModule.forRoot(),
    NzSpinModule,
    SelectModule,
    FormsModule,
    ReactiveFormsModule,
    ValidationErrorsModule,
    DatesModule,
    NzToolTipModule,
    MailAbstractModule,
    AngularSvgIconModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService, Store],
    },
    {
      provide: CustomToastrService,
      useClass: CustomToastrService,
      deps: [ToastrService],
    },
    SnapshotService,
    AuthService,
    TitleService,
    OutlookService,
    NavigationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
