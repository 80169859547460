import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AuthService } from "../../services/auth.service";
import {
  getAllUsersAction,
  getAllUsersFailureAction,
  getAllUsersSuccessAction,
} from "../actions/get-all-users.action";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { AllUsersInterface } from "../../interfaces/user.interface";

@Injectable()
export class GetAllUsersEffect {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
  ) {}

  getAllUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllUsersAction),
      switchMap(() => {
        return this.authService.getAllUsers().pipe(
          map((allUsers: AllUsersInterface[]) => {
            return getAllUsersSuccessAction({ allUsers });
          }),
          catchError(() => {
            return of(getAllUsersFailureAction());
          }),
        );
      }),
    ),
  );
}
