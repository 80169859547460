import { Injectable } from "@angular/core";
import { BreakpointObserver } from "@angular/cdk/layout";

@Injectable()
export class TrackBreakpointService {
  public isMobileScreen = false;
  public isTabletScreen = false;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.trackMobileBreakpoint();
    this.trackTableBreakpoint();
  }

  trackMobileBreakpoint() {
    this.breakpointObserver
      .observe([`(max-width: 550px)`])
      .subscribe((result) => {
        this.isMobileScreen = result.matches;
      });
  }

  trackTableBreakpoint() {
    this.breakpointObserver
      .observe([`(max-width: 1024px)`])
      .subscribe((result) => {
        this.isTabletScreen = result.matches;
      });
  }
}
