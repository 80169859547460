import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError, switchMap, take } from "rxjs/operators";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { Store } from "@ngrx/store";
import { SnapshotService } from "./snapshot.service";
import { DynamicLoaderService } from "./dynamic-loader.serice";
import { userLogoutSuccessAction } from "../store/actions/user-logout.action";
import { NavigationService } from "./navigation.service";
import {
  AllUsersInterface,
  UserInfoInterface,
  UserLoginInterface,
  UserLoginResponseInterface,
  UserRegisterInterface,
} from "../interfaces/user.interface";
import { MsalService } from "@azure/msal-angular";
import { LOCALSTORAGE } from "../enums/localstorage.enum";
import { clearWelcomeMessagesAction } from "../pages/welcome-messages/store/actions/get-welcome-messages.action";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store,
    private snapshotService: SnapshotService,
    private dynamicLoader: DynamicLoaderService,
    private navigationService: NavigationService,
    private msalService: MsalService,
  ) {}

  getAllUsers(): Observable<AllUsersInterface[]> {
    return this.http.get<AllUsersInterface[]>(`user/all`);
  }

  setAuthFromLocalStorage(token: string): boolean {
    if (token) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(token));
      return true;
    }
    return false;
  }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    this.signOut(true).then();
    this.store.dispatch(userLogoutSuccessAction());
    this.store.dispatch(clearWelcomeMessagesAction());
    this.dynamicLoader.loadNotAuthModule().then((NotAuthModule) => {
      this.router.resetConfig([
        {
          path: "",
          loadChildren: () => Promise.resolve(NotAuthModule),
        },
      ]);
      this.router.navigateByUrl("/");
    });
  }

  async signOut(handle?: boolean): Promise<void> {
    if (localStorage.getItem(LOCALSTORAGE.OUTLOOK_TOKEN)) {
      localStorage.removeItem(LOCALSTORAGE.OUTLOOK_TOKEN);
      if (handle) {
        await this.msalService.logout().toPromise();
      }
    }
  }

  getCurrentUser(): Observable<UserInfoInterface> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth) {
      this.navigationService.requestedRoute$
        .pipe(take(1))
        .subscribe((route) => {
          this.navigationService.saveRedirectUrl(route);
        });
    }
    return this.http.get("user").pipe(
      switchMap((user: UserInfoInterface) => {
        this.snapshotService.checkAdminStatus(user.roles);
        this.snapshotService.checkSeoStatus(user.roles);
        this.snapshotService.setIsAuth(true);
        return of(user);
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  public getAuthFromLocalStorage(): string {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken),
      );
      return authData;
    } catch (error) {
      return undefined;
    }
  }

  loginUser(user: UserLoginInterface): Observable<UserLoginResponseInterface> {
    return this.http.post<UserLoginResponseInterface>("auth/sign-in", user);
  }

  registerUser(
    user: UserRegisterInterface,
  ): Observable<UserLoginResponseInterface> {
    const userLogin: UserLoginInterface = {
      username: user.username,
      password: user.password,
    };
    return this.http
      .post<UserLoginResponseInterface>("auth/sign-up", user)
      .pipe(switchMap(() => this.loginUser(userLogin)));
  }
}
