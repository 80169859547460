import { Injectable } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { Subject } from "rxjs";
import { LOCALSTORAGE } from "../enums/localstorage.enum";
import { PAGE_ROUTES } from "../enums/page-routes.enum";

@Injectable()
export class NavigationService {
  private requestedRouteSubject = new Subject<string>();
  requestedRoute$ = this.requestedRouteSubject.asObservable();

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.requestedRouteSubject.next(event.url);
      }
    });
  }

  saveRedirectUrl(url: string) {
    if (
      url.includes(`/${PAGE_ROUTES.LOGIN}`) ||
      url.includes(`/${PAGE_ROUTES.REGISTER}`) ||
      url.includes(`/${PAGE_ROUTES.CONSIDERATION}`)
    ) {
      localStorage.setItem(LOCALSTORAGE.REDIRECT_URL, "/");
    } else {
      localStorage.setItem(LOCALSTORAGE.REDIRECT_URL, url);
    }
  }

  getAndClearRedirectUrl(): string | null {
    const redirectUrl = localStorage.getItem(LOCALSTORAGE.REDIRECT_URL);
    localStorage.setItem(LOCALSTORAGE.REDIRECT_URL, "/");
    return redirectUrl;
  }
}
