import { createAction, props } from "@ngrx/store";
import { WelcomeMessageInterface } from "../../types/welcome-message.interface";

export enum ActionType {
  GET_WELCOME_MESSAGES = "[WELCOME_MESSAGES] Get welcome messages",
  GET_WELCOME_MESSAGES_SUCCESS = "[WELCOME_MESSAGES] Get welcome messages success",
  GET_WELCOME_MESSAGES_FAILURE = "[WELCOME_MESSAGES] Get welcome messages failure",

  CLEAR_WELCOME_MESSAGES = "[WELCOME_MESSAGES] Clear welcome message",
}

export const getWelcomeMessagesAction = createAction(
  ActionType.GET_WELCOME_MESSAGES,
  props<{ tab: string }>(),
);

export const getWelcomeMessagesSuccessAction = createAction(
  ActionType.GET_WELCOME_MESSAGES_SUCCESS,
  props<{ welcomeMessages: WelcomeMessageInterface[] }>(),
);

export const getWelcomeMessagesFailureSuccessAction = createAction(
  ActionType.GET_WELCOME_MESSAGES_FAILURE,
);

export const clearWelcomeMessagesAction = createAction(
  ActionType.CLEAR_WELCOME_MESSAGES,
);
